import { Constants } from '@yescapa-dev/ysc-api-js/legacy'
import type { CamperLandingPageDefinition } from '~/types/camperType'
import { ACLASS_1, CARAVAN_AND_SEA, COACHBUILT_OLDPEOPLE_BREAK, COMBI_AND_ROAD_1, CONVERTEDVAN_WOOD_FOREST, LOWPROFILE_AND_MONTAIN_2, USER_AND_VAN } from '~/constants/pictures'

export enum LandingCamperTypes {
  LOWPROFILE = 'lowprofile',
  COACHBUILT = 'coachbuilt',
  ACLASS = 'aclass',
  CAMPERVAN = 'campervan',
  VAN = 'van',
  COMBI_VW = 'combi-vw',
  CARAVAN = 'caravan',
}

const useAClassLandingDefinition = () => {
  const { $i18n: { t } } = useNuxtApp()

  const aClassLandingDefinitionFactory = (): CamperLandingPageDefinition => ({
    searchQuery: {
      types: `${Constants.PRODUCTS.TYPES.ACLASS}`,
    },
    pageMeta: {
      title: t('pages.camper_types.aclass.meta_title'),
      description: t('pages.camper_types.aclass.meta_description'),
    },
    hero: {
      image: twicpicsPath(ACLASS_1, { isLibrary: true }),
      title: t('pages.camper_types.aclass.hero_title'),
      titleSeo: t('pages.camper_types.aclass.hero_title_seo'),
    },
    sectionCampers: {
      title: t('pages.camper_types.aclass.search_title'),
      description: t('pages.camper_types.aclass.search_desc'),
    },
    sectionHowItWorks: {
      title: t('pages.camper_types.aclass.how_it_works_title'),
      subtitle: t('pages.camper_types.aclass.how_it_works_subtitle'),
    },
    sectionOwner: {
      title: t('pages.camper_types.aclass.complete_confidence'),
    },
    rentLinkLabel: t('commons.rent_a_camper.aclass'),
    contents: ['camper-type/aclass/aclass1', 'camper-type/aclass/aclass2'],
    faq: {
      title: t('pages.camper_types.aclass.faq_title'),
      items: [],
    },
  })

  return { aClassLandingDefinitionFactory }
}

const useCamperVanLandingDefinition = () => {
  const { $i18n: { t } } = useNuxtApp()

  const camperVanLandingDefinitionFactory = (): CamperLandingPageDefinition => ({
    searchQuery: {
      types: `${Constants.PRODUCTS.TYPES.CAMPERVAN}`,
    },
    pageMeta: {
      title: t('pages.camper_types.campervan.meta_title'),
      description: t('pages.camper_types.campervan.meta_description'),
    },
    hero: {
      image: twicpicsPath(CONVERTEDVAN_WOOD_FOREST, { isLibrary: true }),
      title: t('pages.camper_types.campervan.hero_title'),
      titleSeo: t('pages.camper_types.campervan.hero_title_seo'),
    },
    sectionCampers: {
      title: t('pages.camper_types.campervan.search_title'),
      description: t('pages.camper_types.campervan.search_desc'),
    },
    sectionHowItWorks: {
      title: t('pages.camper_types.campervan.how_it_works_title'),
      subtitle: t('pages.camper_types.campervan.how_it_works_subtitle'),
    },
    sectionOwner: {
      title: t('pages.camper_types.campervan.complete_confidence'),
    },
    rentLinkLabel: t('commons.rent_a_camper.campervan'),
    contents: ['camper-type/campervan/campervan1', 'camper-type/campervan/campervan2'],
    faq: {
      title: t('pages.camper_types.campervan.faq_title'),
      items: [],
    },
  })

  return { camperVanLandingDefinitionFactory }
}

const useCaravanLandingDefinition = () => {
  const { $i18n: { t } } = useNuxtApp()

  const caravanLandingDefinitionFactory = (): CamperLandingPageDefinition => ({
    searchQuery: {
      types: `${Constants.PRODUCTS.TYPES.CARAVAN}`,
    },
    pageMeta: {
      title: t('pages.camper_types.caravan.meta_title'),
      description: t('pages.camper_types.caravan.meta_description'),
    },
    hero: {
      image: twicpicsPath(CARAVAN_AND_SEA, { isLibrary: true }),
      title: t('pages.camper_types.caravan.hero_title'),
      titleSeo: t('pages.camper_types.caravan.hero_title_seo'),
    },
    sectionCampers: {
      title: t('pages.camper_types.caravan.search_title'),
      description: t('pages.camper_types.caravan.search_desc'),
    },
    sectionHowItWorks: {
      title: t('pages.camper_types.caravan.how_it_works_title'),
      subtitle: t('pages.camper_types.caravan.how_it_works_subtitle'),
    },
    rentLinkLabel: t('commons.rent_a_camper.caravan'),
    sectionOwner: {
      title: t('pages.camper_types.caravan.complete_confidence'),
    },
    contents: ['camper-type/caravan/caravan1', 'camper-type/caravan/caravan2'],
    faq: {
      title: t('pages.camper_types.caravan.faq_title'),
      items: [],
    },
  })

  return { caravanLandingDefinitionFactory }
}

const useCombiVwLandingDefinition = () => {
  const { $i18n: { t } } = useNuxtApp()

  const combiVwLandingDefinitionFactory = (): CamperLandingPageDefinition => ({
    searchQuery: {
      meta_type: 'combi',
      types: `${Constants.PRODUCTS.TYPES.VAN}`,
    },
    pageMeta: {
      title: t('pages.camper_types.combi_vw.meta_title'),
      description: t('pages.camper_types.combi_vw.meta_description'),
    },
    hero: {
      image: twicpicsPath(COMBI_AND_ROAD_1, { isLibrary: true }),
      title: t('pages.camper_types.combi_vw.hero_title'),
      titleSeo: t('pages.camper_types.combi_vw.hero_title_seo'),
    },
    sectionCampers: {
      title: t('pages.camper_types.combi_vw.search_title'),
      description: t('pages.camper_types.combi_vw.search_desc'),
    },
    sectionOwner: {
      title: t('pages.camper_types.combi_vw.complete_confidence'),
    },
    sectionHowItWorks: {
      title: t('pages.camper_types.combi_vw.how_it_works_title'),
      subtitle: t('pages.camper_types.combi_vw.how_it_works_subtitle'),
    },
    rentLinkLabel: t('commons.rent_a_camper.combi'),
    contents: ['camper-type/combi-vw/combi-vw1', 'camper-type/combi-vw/combi-vw2'],
    faq: {
      title: t('pages.camper_types.combi_vw.faq_title'),
      items: [],
    },
  })

  return { combiVwLandingDefinitionFactory }
}

const useLowProfileLandingDefinition = () => {
  const { $i18n: { t } } = useNuxtApp()

  const lowProfileLandingDefinitionFactory = (): CamperLandingPageDefinition => ({
    searchQuery: {
      types: `${Constants.PRODUCTS.TYPES.LOWPROFILE}`,
    },
    pageMeta: {
      title: t('pages.camper_types.lowprofile.meta_title'),
      description: t('pages.camper_types.lowprofile.meta_description'),
    },
    hero: {
      image: twicpicsPath(LOWPROFILE_AND_MONTAIN_2, { isLibrary: true }),
      title: t('pages.camper_types.lowprofile.hero_title'),
      titleSeo: t('pages.camper_types.lowprofile.hero_title_seo'),
    },
    sectionCampers: {
      title: t('pages.camper_types.lowprofile.search_title'),
      description: t('pages.camper_types.lowprofile.search_desc'),
    },
    sectionOwner: {
      title: t('pages.camper_types.lowprofile.complete_confidence'),
    },
    sectionHowItWorks: {
      title: t('pages.camper_types.lowprofile.how_it_works_title'),
      subtitle: t('pages.camper_types.lowprofile.how_it_works_subtitle'),
    },
    rentLinkLabel: t('commons.rent_a_camper.lowprofile'),
    contents: ['camper-type/lowprofile/lowprofile1', 'camper-type/lowprofile/lowprofile2'],
    faq: {
      title: t('pages.camper_types.lowprofile.faq_title'),
      items: [],
    },
  })

  return { lowProfileLandingDefinitionFactory }
}

const useCoachBuiltLandingDefinition = () => {
  const { $i18n: { t } } = useNuxtApp()

  const coachBuiltLandingDefinitionFactory = (): CamperLandingPageDefinition => ({
    searchQuery: {
      types: `${Constants.PRODUCTS.TYPES.COACHBUILT}`,
    },
    pageMeta: {
      title: t('pages.camper_types.coachbuilt.meta_title'),
      description: t('pages.camper_types.coachbuilt.meta_description'),
    },
    hero: {
      image: twicpicsPath(COACHBUILT_OLDPEOPLE_BREAK, { isLibrary: true }),
      title: t('pages.camper_types.coachbuilt.hero_title'),
      titleSeo: t('pages.camper_types.coachbuilt.hero_title_seo'),
    },
    sectionCampers: {
      title: t('pages.camper_types.coachbuilt.search_title'),
      description: t('pages.camper_types.coachbuilt.search_desc'),
    },
    sectionOwner: {
      title: t('pages.camper_types.coachbuilt.complete_confidence'),
    },
    sectionHowItWorks: {
      title: t('pages.camper_types.coachbuilt.how_it_works_title'),
      subtitle: t('pages.camper_types.coachbuilt.how_it_works_subtitle'),
    },
    rentLinkLabel: t('commons.rent_a_camper.coachbuilt'),
    contents: ['camper-type/coachbuilt/coachbuilt1', 'camper-type/coachbuilt/coachbuilt2'],
    faq: {
      title: t('pages.camper_types.coachbuilt.faq_title'),
      items: [],
    },
  })

  return { coachBuiltLandingDefinitionFactory }
}

const useVanLandingDefinition = () => {
  const { $i18n: { t } } = useNuxtApp()

  const vanLandingDefinitionFactory = (): CamperLandingPageDefinition => ({
    searchQuery: {
      types: `${Constants.PRODUCTS.TYPES.VAN}`,
    },
    pageMeta: {
      title: t('pages.camper_types.van.meta_title'),
      description: t('pages.camper_types.van.meta_description'),
    },
    hero: {
      image: twicpicsPath(USER_AND_VAN, { isLibrary: true }),
      title: t('pages.camper_types.van.hero_title'),
      titleSeo: t('pages.camper_types.van.hero_title_seo'),
    },
    sectionCampers: {
      title: t('pages.camper_types.van.search_title'),
      description: t('pages.camper_types.van.search_desc'),
    },
    sectionOwner: {
      title: t('pages.camper_types.van.complete_confidence'),
    },
    sectionHowItWorks: {
      title: t('pages.camper_types.van.how_it_works_title'),
      subtitle: t('pages.camper_types.van.how_it_works_subtitle'),
    },
    rentLinkLabel: t('commons.rent_a_camper.van'),
    contents: ['camper-type/van/van1', 'camper-type/van/van2'],
    faq: {
      title: t('pages.camper_types.van.faq_title'),
      items: [],
    },
  })

  return { vanLandingDefinitionFactory }
}

export const useCamperTypeLandingDefinition = (slug: MaybeRefOrGetter<string>) => {
  const { lowProfileLandingDefinitionFactory } = useLowProfileLandingDefinition()
  const { coachBuiltLandingDefinitionFactory } = useCoachBuiltLandingDefinition()
  const { aClassLandingDefinitionFactory } = useAClassLandingDefinition()
  const { camperVanLandingDefinitionFactory } = useCamperVanLandingDefinition()
  const { vanLandingDefinitionFactory } = useVanLandingDefinition()
  const { combiVwLandingDefinitionFactory } = useCombiVwLandingDefinition()
  const { caravanLandingDefinitionFactory } = useCaravanLandingDefinition()

  const factoryMap: Record<LandingCamperTypes, () => CamperLandingPageDefinition> = {
    [LandingCamperTypes.LOWPROFILE]: lowProfileLandingDefinitionFactory,
    [LandingCamperTypes.COACHBUILT]: coachBuiltLandingDefinitionFactory,
    [LandingCamperTypes.ACLASS]: aClassLandingDefinitionFactory,
    [LandingCamperTypes.CAMPERVAN]: camperVanLandingDefinitionFactory,
    [LandingCamperTypes.VAN]: vanLandingDefinitionFactory,
    [LandingCamperTypes.COMBI_VW]: combiVwLandingDefinitionFactory,
    [LandingCamperTypes.CARAVAN]: caravanLandingDefinitionFactory,
  }

  const isValidSlug = (slug: string): slug is LandingCamperTypes => {
    return (Object.values(LandingCamperTypes) as string[]).includes(slug)
  }

  const landingDefinition = computed<CamperLandingPageDefinition | undefined>(() => {
    const unwrappedSlug = toValue(slug)

    if (isValidSlug(unwrappedSlug)) {
      return factoryMap[unwrappedSlug]()
    }

    return undefined
  })

  return { landingDefinition }
}
