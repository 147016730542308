<script setup lang="ts">
import type { GeolocationListItemResponse } from '@yescapa-dev/ysc-api-js/legacy'
import type { RouteLocationRaw } from '#vue-router'

interface Props {
  countries: GeolocationListItemResponse[]
}

const props = defineProps<Props>()

const { routing } = storeToRefs(useRoutingStore())
const { t } = useI18n()

const countriesLinks = computed(() => getPlaceLinks(props.countries))

const landingLinks: {
  key: string
  name: string | null
  url: RouteLocationRaw
}[] = [
  { key: 'commons.camper_children', name: t('commons.camper_children'), url: { name: 'rv-hire-family' } },
  // @ts-expect-error mssing type in routing
  { key: 'commons.camper_couple', name: t('commons.camper_couple'), url: routing.value?.landings.couple },
  { key: 'commons.camper_animals', name: t('commons.camper_animals'), url: { name: 'rv-hire-with-pets' } },
  { key: 'commons.camper_surftrip', name: t('commons.camper_surftrip'), url: { name: 'surftrip' } },
  { key: 'commons.camper_marriage', name: t('commons.camper_marriage'), url: routing.value?.landings.wedding },
  { key: 'commons.camper_volkswagen', name: t('commons.camper_volkswagen'), url: { name: 'we-love-california' } },
  { key: 'van', name: useVehicleType({ type: 'van' }), url: { name: 'camper-type-slug', params: { slug: LandingCamperTypes.VAN } } },
  { key: 'campervan', name: useVehicleType({ type: 'campervan' }), url: { name: 'camper-type-slug', params: { slug: LandingCamperTypes.CAMPERVAN } } },
  { key: 'lowprofile', name: useVehicleType({ type: 'lowprofile' }), url: { name: 'camper-type-slug', params: { slug: LandingCamperTypes.LOWPROFILE } } },
  { key: 'coachbuilt', name: useVehicleType({ type: 'coachbuilt' }), url: { name: 'camper-type-slug', params: { slug: LandingCamperTypes.COACHBUILT } } },
  { key: 'aclass', name: useVehicleType({ type: 'aclass' }), url: { name: 'camper-type-slug', params: { slug: LandingCamperTypes.ACLASS } } },
  { key: 'caravan', name: useVehicleType({ type: 'caravan' }), url: { name: 'camper-type-slug', params: { slug: LandingCamperTypes.CARAVAN } } },
].filter(({ url }) => !!url)
</script>

<template>
  <div class="container py-24">
    <AppLandingLinks
      :links="countriesLinks"
    >
      <template #title>
        {{ $t('pages.index.camper_rental_in_europe') }}
      </template>
      <template #description>
        {{ $t('pages.index.next_roadtrip_is_waiting') }}
      </template>
      <template #links="{ links }">
        <AppLandingLink
          v-for="country in links"
          :key="country.name"
          :to="country.url"
        >
          {{ country.name }}
        </AppLandingLink>
      </template>
    </AppLandingLinks>

    <AppLandingLinks
      :links="landingLinks"
      class="mt-14"
    >
      <template #title>
        {{ $t('commons.actions.rent_a_camper_between_individuals') }}
      </template>
      <template #description>
        {{ $t('pages.index.a_camper_for_every_team') }}
      </template>
      <template #links="{ links }">
        <AppLandingLink
          v-for="landingLink in links"
          :key="landingLink.key"
          :to="landingLink.url"
        >
          {{ landingLink.name }}
        </AppLandingLink>
      </template>
    </AppLandingLinks>
  </div>
</template>
