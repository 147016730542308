import { Constants } from '@yescapa-dev/ysc-api-js/legacy'
import { LandingCamperTypes } from './useCamperTypeLandingDefinition'
import type { RouteLocationRaw } from '#vue-router'
import { DRAWING_A_CLASS, DRAWING_COACHBUILT_BIKES, DRAWING_COMBI, DRAWING_CONVERTED_VAN_SURF, DRAWING_LOW_PROFILE_BIKES, DRAWING_T6_TOPUP } from '~/constants/pictures'

export interface VehicleTypeDrawingDefinition {
  slug: LandingCamperTypes
  name: string | null
  subtitle: string
  description: string
  linkLabel: string
  linkUrl: RouteLocationRaw
  image: string
  bgColor: string
}

export const useVehicleTypeDrawing = ({
  bgColors,
  filter = undefined,
}: {
  bgColors: MaybeRefOrGetter<string[]>
  filter?: (v: VehicleTypeDrawingDefinition) => boolean
}) => {
  const { $i18n: { t } } = useNuxtApp()

  const vehicleTypes = computed<VehicleTypeDrawingDefinition[]>(() => {
    let vehicleTypes: VehicleTypeDrawingDefinition[] = [
      {
        slug: LandingCamperTypes.VAN,
        name: useVehicleType({ type: 'van' }),
        subtitle: t('pages.index.van_subtitle'),
        description: t('pages.index.van_description'),
        linkLabel: t('commons.rent_a_camper.van'),
        linkUrl: {
          name: 's',
          query: { types: Constants.PRODUCTS.TYPES.VAN },
        },
        image: DRAWING_T6_TOPUP,
        bgColor: 'bg-pink-100',
      },
      {
        slug: LandingCamperTypes.CAMPERVAN,
        name: useVehicleType({ type: 'campervan' }),
        subtitle: t('pages.index.campervan_subtitle'),
        description: t('pages.index.campervan_description'),
        linkLabel: t('commons.rent_a_camper.campervan'),
        linkUrl: {
          name: 's',
          query: { types: Constants.PRODUCTS.TYPES.CAMPERVAN },
        },
        image: DRAWING_CONVERTED_VAN_SURF,
        bgColor: 'bg-pink-100',
      },
      {
        slug: LandingCamperTypes.LOWPROFILE,
        name: useVehicleType({ type: 'lowprofile' }),
        subtitle: t('pages.index.lowprofile_subtitle'),
        description: t('pages.index.lowprofile_description'),
        linkLabel: t('commons.rent_a_camper.lowprofile'),
        linkUrl: {
          name: 's',
          query: { types: Constants.PRODUCTS.TYPES.LOWPROFILE },
        },
        image: DRAWING_LOW_PROFILE_BIKES,
        bgColor: 'bg-pink-100',
      },
      {
        slug: LandingCamperTypes.COACHBUILT,
        name: useVehicleType({ type: 'coachbuilt' }),
        subtitle: t('pages.index.coachbuilt_subtitle'),
        description: t('pages.index.coachbuilt_description'),
        linkLabel: t('commons.rent_a_camper.coachbuilt'),
        linkUrl: {
          name: 's',
          query: { types: Constants.PRODUCTS.TYPES.COACHBUILT },
        },
        image: DRAWING_COACHBUILT_BIKES,
        bgColor: 'bg-pink-100',
      },
      {
        slug: LandingCamperTypes.ACLASS,
        name: useVehicleType({ type: 'aclass' }),
        subtitle: t('pages.index.aclass_subtitle'),
        description: t('pages.index.aclass_description'),
        linkLabel: t('commons.rent_a_camper.aclass'),
        linkUrl: {
          name: 's',
          query: { types: Constants.PRODUCTS.TYPES.ACLASS },
        },
        image: DRAWING_A_CLASS,
        bgColor: 'bg-pink-100',
      },
      {
        slug: LandingCamperTypes.COMBI_VW,
        name: useVehicleType({ type: 'combi' }),
        subtitle: t('pages.index.combi_subtitle'),
        description: t('pages.index.combi_description'),
        linkLabel: t('commons.rent_a_camper.combi'),
        linkUrl: {
          name: 's',
          query: { meta_type: 'combi' },
        },
        image: DRAWING_COMBI,
        bgColor: 'bg-pink-100',
      },
    ]

    if (filter) {
      vehicleTypes = vehicleTypes.filter(filter)
    }

    const unwrappedBgColor = toValue(bgColors)

    return vehicleTypes.map((item, index) => ({
      ...item,
      bgColor: unwrappedBgColor[index % unwrappedBgColor.length],
    }))
  })

  return { vehicleTypes }
}
